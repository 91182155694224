/** @jsxImportSource @emotion/react */
import { Global } from "@emotion/react";
import { Button, Grid, Typography } from "@mui/material";
import { useRouter } from "next/router";
import NeonTypography from "../components/typography/NeonTypography";

function PageNotFound() {
	const router = useRouter();
	return (
		<Grid container css={{ height: "100vh" }} alignItems="center" justifyContent="center">
			<Global
				styles={{
					body: {
						backgroundColor: "#F8FFFD",
					},
				}}
			/>
			<Grid item>
				<Grid container justifyContent="center" direction="column">
					<Grid item sx={{ textAlign: "center" }}>
						<img crossOrigin="" css={{ width: "100%", maxWidth: 320 }} alt="404 missing page" src="/images/404-image.png" />
					</Grid>
					<Grid item sx={{ textAlign: "center", px: 4 }}>
						<NeonTypography children="404" size={60} />
						<Typography marginTop={8} variant="h5" children="Oh, no! Questa pagina non è stata trovata!" />
						<Button
							variant="contained"
							size="large"
							sx={{ marginTop: 10 }}
							children="Torna alla pagina precedente"
							onClick={() => {
								router.back();
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default PageNotFound;
